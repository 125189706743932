<template>
  <div>
    <div class="title">学习进度</div>
    <div class="content">
      <div class="flex" style="margin-bottom: 48px">
        <el-input
          class="ipt"
          v-model="input"
          placeholder="输入学习课程名称"
        ></el-input>
        <div class="search" @click="search">搜索</div>
      </div>
      <div class="list" v-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="flex">
            <img :src="item.cover || scr" alt="" />
            <div class="item_1">
              <div class="item_1_1">
                {{ item.title }}
              </div>
              <div class="item_1_2">{{ item.total_people }}人已学</div>
            </div>
          </div>
          <div>
            <div class="item_2">已学课时</div>
            <div class="item_3">{{ item.class_hour }}课时</div>
          </div>
        </div>
      </div>
      <img
        v-if="list.length == 0"
        class="img"
        src="../../assets/kong_yemian_imgs.png"
        alt=""
      />
      <div v-if="list.length == 0" class="txt">暂无班级进度</div>
      <div style="height: 500px"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      scr: require("../../assets/mr.jpg"),
      input: "",
      list: [],
      list1: [],
      page: 1,
    };
  },
  methods: {
    ...mapActions(["getMinePace", "getmyClass_x"]),
    // 搜索课程
    search() {
      let list = [];
      this.list1.forEach((item, index) => {
        let arr = item.title.indexOf(this.input);
        if (arr != -1) {
          list.push(item);
        }
      });
      if (list.length == 0) {
        this.list = this.list1;
      } else {
        this.list = list;
      }
    },
    // 列表数据
    getlist() {
      ++this.page;
      this.getMinePace({
        student_id: this.userInfo.id, //学员id
        title: "", //班级名称
        type: "1", //1. 正在学 2.已完成
        form: "1", //1. 显示进度条 2. 不显示
        page: this.page,
        pageSize: "15",
      }).then((res) => {
        if (res.successCode == 200) {
          this.list.push(...responseBody.list);
          this.list1.push(...responseBody.list);
          if (res.responseBody.list.length == 15) {
            this.getlist();
          }
        }
      });
    },
  },
  mounted() {
    this.getmyClass_x({
      student_id: this.userInfo.id, //学员id
      title: "", //班级名称
      type: "1", //1. 正在学 2.已完成
      form: "1", //1. 显示进度条 2. 不显示
      page: this.page,
      pageSize: "15",
    }).then((res) => {
      if (res.successCode == 200) {
        this.list = res.responseBody.list;
        this.list1 = res.responseBody.list;
        if (res.responseBody.list.length == 15) {
          this.getlist();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}

.content {
  padding: 40px;
  box-sizing: border-box;
  margin-top: 14px;
  background-color: #fff;
  .ipt {
    width: 384px;
    height: 35px;
    background: #ffffff;
    border: 0px none;
  }
  .search {
    cursor: pointer;
    margin-left: 14px;
    text-align: center;
    background-color: #edf5fe;
    width: 93px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #d8e1ec;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
  }
  .list {
    .item {
      margin-bottom: 33px;
      border-bottom: 1px solid #e1e1e1;
      height: 115px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      img {
        width: 140px;
        height: 81px;
      }
      .item_1 {
        margin-left: 24px;
        .item_1_1 {
          width: 306px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7c7c7c;
        }
        .item_1_2 {
          margin-top: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7c7c7c;
        }
      }
      .item_2 {
        text-align: right;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
      .item_3 {
        cursor: pointer;
        margin-top: 3px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
    }
  }
  .img {
    display: block;
    margin: 0 auto;
  }
  .txt {
    text-align: center;
    margin-top: 30px;
    color: #ccc;
  }
}
</style>
